<template>
    <div>
        <OTPModalControl @cancel="cancel"
            @resend="resend"
            @matches="matches"
            @showloader="showLoader"
            @hideloader="hideLoader"
            v-bind:show="show_otp"
            v-bind:otp="otp"
            v-bind:pin="pin"
            v-bind:name="first_name"
            v-bind:mobile="getMobile()"
            v-bind:email="email"
            v-bind:type="type" />
        <div class="fixed w-full">
            <NavbarSection title="Create an account" />
        </div>
        <ContentWrapper>
            <div class="h-navbar mb-4"></div>
            <div class="bg-white p-6 rounded shadow mb-4 leading-normal">
                <AlertControl @closes="closed"
                    v-bind:show="display" 
                    v-bind:theme="theme" 
                    dismiss>
                    {{ message }}
                </AlertControl>
                <div class="mb-4">
                    <div class="flex">
                        <div class="flex-1 mr-3">
                            <TextControl @typing="typing"
                                @blur="blur" 
                                v-bind:value="account_no"
                                v-bind:disabled="freezed || disable_account"
                                type="text" 
                                name="account_no" 
                                :maxlength="6" 
                                placeholder="Account No." 
                                uppercase />
                        </div>
                        <div class="flex-1">
                            <TextControl @typing="typing" 
                                @blur="blur"
                                v-bind:value="serial_no"
                                v-bind:disabled="freezed || disable_serial"
                                type="text" 
                                name="serial_no" 
                                :maxlength="9" 
                                placeholder="Serial No." 
                                uppercase />
                        </div>
                    </div>
                    <div class="mt-1 text-xs text-grey-dark italic">You can provide either account number or serial number.</div>
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing"
                        v-bind:value="last_name"
                        type="text" 
                        name="last_name" 
                        placeholder="Last Name" 
                        uppercase
                        disabled />
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="first_name"
                        type="text" 
                        name="first_name" 
                        placeholder="First Name" 
                        uppercase
                        disabled />
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="middle_name"
                        type="text" 
                        name="middle_name" 
                        placeholder="Middle Name" 
                        uppercase
                        disabled />
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing"
                        v-bind:value="email"
                        v-bind:disabled="freezed"
                        type="text" 
                        name="email" 
                        placeholder="Email" />
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="mobile"
                        v-bind:disabled="freezed"
                        type="mobile" 
                        name="mobile" 
                        placeholder="Mobile No." />
                </div>
                <div class="mb-4">
                    <div>
                        <TextControl @typing="typing" 
                            v-bind:value="username"
                            v-bind:disabled="freezed"
                            type="text" 
                            name="username" 
                            placeholder="Username" 
                            required />
                    </div>
                    <div class="mt-1 text-xs text-grey-dark italic">Username must be at least six characters and must not contain spaces and special characters.</div>
                </div>
                <div class="mb-4">
                    <div>
                        <TextControl @typing="typing" 
                            v-bind:value="password1"
                            v-bind:disabled="freezed"
                            type="password" 
                            name="password1" 
                            placeholder="Password" required />
                    </div>
                    <div class="mt-1 text-xs text-grey-dark italic">Use eight or more characters with a mix of letters, numbers and symbols.</div>
                </div>
                <div class="mb-4">
                    <TextControl @typing="typing" 
                        v-bind:value="password2"
                        v-bind:disabled="freezed"
                        type="password" 
                        name="password2" 
                        placeholder="Confirm Password" 
                        nohint 
                        required />
                </div>
                <div class="mb-4 text-xs text-grey-dark mt-6">
                    <CheckboxControl @toggle="toggle" v-bind:checked="agree">
                        <div>
                            I agreee with the <router-link to="/end-user-license-agreement" class="no-underline text-primary font-bold">End User License Agreement</router-link>
                            and <router-link to="/privacy-policy" class="no-underline text-primary font-bold">Privacy Policy</router-link>.
                        </div>
                    </CheckboxControl>
                </div>
                <div>
                    <ButtonControl v-on:click="join" v-bind:disabled="disabled">Join</ButtonControl>
                </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
import AlertControl from './../components/controls/AlertControl.vue';
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapper from './../components/controls/ContentWrapperControl.vue';
import CheckboxControl from './../components/controls/CheckboxControl.vue';
import NavbarSection from './../components/sections/NavbarSection.vue';
import OTP from './../services/OTP.js';
import OTPModalControl from './../components/controls/OTPModalControl.vue';
import TextControl from './../components/controls/TextControl.vue';
import User from './../services/User.js';

export default {
    components: {
        AlertControl,
        ButtonControl,
        ContentWrapper,
        CheckboxControl,
        NavbarSection,
        OTPModalControl,
        TextControl,
    },
    data() {
        return {
            type: 2,
            disabled: true,
            last_name: '',
            first_name: '',
            middle_name: '',
            account_no: '',
            serial_no: '',
            username: '',
            email: '',
            mobile: '',
            password1: '',
            password2: '',
            display: false,
            message: '',
            otp: null,
            pin: null,
            show_otp: false,
            agree: false,
            freezed: false,
            disable_serial: false,
            disable_account: false,
            theme: 'error',
        }
    },
    mounted() {
        this.$emit('checkauth', false);

        let state = this.$store.state.registration;

        this.last_name = state.last_name;
        this.first_name = state.first_name;
        this.middle_name = state.middle_name;
        this.account_no = state.account_no;
        this.serial_no = state.serial_no;
        this.username = state.username;
        this.email = state.email;
        this.mobile = state.mobile;
        this.password1 = state.password1;
        this.password2 = state.password2;
        this.agree = state.agree;
    },
    methods: {
        blur(obj) {
            if(obj.value.length == 6) {
                let name = obj.name.toLowerCase(),
                    that = this;

                this.$emit('showloader');

                if(name == 'account_no') {
                    User.findAccount(obj.value.toUpperCase()).then(function(response) {
                        let data = response.data.data;

                        that.first_name = data.first_name;
                        that.last_name = data.last_name;
                        that.middle_name = data.middle_name;
                        that.disable_account = false;
                        that.disable_serial = true;
                        that.$emit('hideloader');
                    }).catch(function() {
                        that.$emit('hideloader');
                    });
                }
                else if(name == 'serial_no') {
                    User.findSerial(obj.value.toUpperCase()).then(function(response) {
                        let data = response.data.data;

                        that.first_name = data.first_name;
                        that.last_name = data.last_name;
                        that.middle_name = data.middle_name;
                        that.disable_account = true;
                        that.disable_serial = false;
                        that.$emit('hideloader');
                    }).catch(function() {
                        that.$emit('hideloader');
                    });
                }
            }
        },
        toggle(n) {
            this.agree = n;
            this.$store.state.registration.agree = n;
            this.disableButton();
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.$store.state.registration[obj.name] = obj.value;
            this.disableButton();
        },
        disableButton() {
            this.disabled = (this.last_name.length == 0 ||
                this.first_name.length == 0 ||
                (this.account_no.length == 0 && this.serial_no.length == 0) ||
                this.username.length == 0 ||
                (this.email.length == 0 && this.mobile.length == 0) ||
                this.password1.length == 0 ||
                this.password2.length == 0 ||
                !this.agree
            );
        },
        closed() {
            this.display = false;
        },
        join() {
            this.$emit('showloader');
            let that = this;

            User.validate({
                first_name: this.first_name,
                last_name: this.last_name,
                middle_name: this.middle_name,
                account_no: this.account_no.toUpperCase(),
                serial_no: this.serial_no.toUpperCase(),
                email: this.email,
                mobile: this.getMobile(),
                username: this.username,
                password1: this.password1,
                password2: this.password2,
            }).then(function() {
                that.matches();
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.password1 = '';
                that.password2 = '';
                that.agree = false;
                that.$emit('hideloader');
                window.scrollTo(0, 0);
            });
        },
        requestOTP() {
            let that = this;

            OTP.request(this.type, this.first_name, this.email, this.getMobile(), this.pin).then(function(response) {
                that.otp = response.data.data.otp;
                that.pin = response.data.data.pin;
                that.$emit('hideloader');
                that.show_otp = true;
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.$emit('hideloader');
                window.scrollTo(0, 0);
            });
        },
        showLoader() {
            this.$emit('showloader');
        },
        hideLoader() {
            this.$emit('hideloader');
        },
        cancel() {
            this.show_otp = false;
        },
        resend(otp) {
            this.otp = otp;
        },
        getMobile() {
            return '+63' + this.mobile;
        },
        matches() {
            let that = this;

            this.disabled = true;
            this.freezed = true;
            this.$emit('showloader');

            User.create({
                first_name: this.first_name,
                last_name: this.last_name,
                middle_name: this.middle_name,
                account_no: this.account_no.toUpperCase(),
                serial_no: this.serial_no.toUpperCase(),
                email: this.email,
                mobile: this.getMobile(),
                username: this.username,
                password1: this.password1,
                password2: this.password2,
            }).then(function() {
                that.$cookies.set("register", that.first_name);
                that.$store.state.credentials.username = that.username;
                that.$store.state.credentials.password = that.password2;
                that.$router.push('/signup/done');
                that.$emit('hideloader');
            }).catch(function(error) {
                that.theme = 'error';
                that.message = error.response.data.message;
                that.display = true;
                that.password1 = '';
                that.password2 = '';
                that.agree = false;
                that.$emit('hideloader');
                window.scrollTo(0, 0);
            });
        }
    },
}
</script>