import service from './../service.js';

export default {
    authenticate(username, password, attempt) {
        return service.post('/user/authenticate', {
            username: username,
            password: password,
            attempt: attempt,
        });
    },
    
    logout(uuid, hash) {
        return service.post('/user/' + uuid + '/logout', {
            hash: hash,
        });
    },
    
    getInfo(uuid) {
        return service.get('/user/' + uuid);
    },

    updateUsername(uuid, username) {
        return service.put('/user/' + uuid + '/username', {
            value: username,
        });
    },

    updatePassword(uuid, old_password, new_password, con_password, require_old = 1) {
        return service.put('/user/' + uuid + '/password', {
            old_password: old_password,
            new_password: new_password,
            con_password: con_password,
            require_old: require_old,
        });
    },

    validateEmail(uuid, email) {
        return service.post('/user/' + uuid + '/email', {
            value: email,
        });
    },

    updateEmail(uuid, email) {
        return service.put('/user/' + uuid + '/email', {
            value: email,
        })
    },

    validateMobile(uuid, mobile) {
        return service.post('/user/' + uuid + '/mobile', {
            value: mobile,
        });
    },

    updateMobile(uuid, mobile) {
        return service.put('/user/' + uuid + '/mobile', {
            value: mobile,
        });
    },

    testPassword(uuid, password) {
        return service.post('/user/' + uuid + '/password/validate', {
            password: password,
        });
    },

    deactivate(uuid) {
        return service.delete('/user/' + uuid);
    },

    validate(payload) {
        return service.post('/user/register/validate', payload);
    },

    create(payload) {
        payload.client = service.getClientId();

        return service.post('/user/register', payload);
    },

    forgotPassword(value) {
        return service.post('/user/forgot', {
            value: value,
        });
    },

    revert(uuid) {
        return service.put('/user/' + uuid + '/revert');
    },

    isEmailAvailable(email) {
        return service.post('/user/test', {
            value: email,
            type: 2,
        });
    },

    isMobileAvailable(mobile) {
        return service.post('/user/test', {
            value: mobile,
            type: 3,
        });
    },

    findAccount(account) {
        return service.get('/member/find-account/' + account);
    },

    findSerial(serial) {
        return service.get('/member/find-serial/' + serial);
    }
}