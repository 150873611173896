<template>
    <div class="flex">
        <div class="flex items-center justify-content">
            <img v-on:click="toggle" v-bind:src="getIcon()" class="w-menu" />
        </div>
        <div class="flex-grow flex items-center justify-content pl-1">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-checkbox',
    props: {
        checked: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            state: false,
        }
    },
    mounted() {
        this.state = this.checked;
    },
    methods: {
        getIcon() {
            return require(this.state ? "@/assets/icons/papsi_ck_on.svg" : "@/assets/icons/papsi_ck_off.svg");
        },
        toggle() {
            if(!this.disabled) {
                this.state = !this.state;
                this.$emit('toggle', this.state);
            }
        }
    },
    watch: {
        checked(n) {
            this.state = n;
        }
    }
}
</script>