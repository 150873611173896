<template>
    <div>
        <div class="w-full pb-1 text-grey-darker text-md" v-if="placeholder.length != 0">
            <label v-text="placeholder"></label>
            <span v-show="required" class="text-red">*</span>
        </div>
        <div v-bind:class="[ border ]" class="border rounded overflow-hidden flex flex-row">
            <div v-show="isMobile()" class="text-grey-dark text-sm px-2 border-r font-bold papsi-txt-lh">
                +63
            </div>
            <div class="flex-grow">
                <input v-model="val"
                    v-bind:type="current_type"
                    v-bind:maxlength="max"
                    v-bind:value="val"
                    v-bind:disabled="disabled"
                    v-bind:class="{ 'uppercase': uppercase }"
                    v-on:focus="focus"
                    v-on:blur="blur"
                    ref="textbox"
                    class="w-full p-3 outline-none text-md border-none"
                    autocomplete="off"
                    spellcheck="false" />
            </div>
            <div v-show="isPassword() && !nohint" class="px-2 bg-white">
                <img v-bind:src="getIcon()"
                    v-on:click="toggle"
                    class="w-icon h-icon papsi-icon-mt" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-text',
    props: {
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "text",
        },
        placeholder: {
            type: String,
            default: '',
        },
        maxlength: {
            type: Number,
            default: null,
        },
        value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        uppercase: {
            type: Boolean,
            default: false,
        },
        nohint: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            current_type: 'text',
            border: 'border-grey',
            val: '',
            visible: false,
            max: null,
        }
    },
    mounted() {
        if(this.isMobile()) {
            this.max = 10;
            this.current_type = 'text';
        }
        else
        {
            this.max = this.maxlength;
        }

        if(this.isPassword()) {
            this.current_type = 'password';
        }
    },
    methods: {
        focus() {
            this.border = 'border-primary';
            
            this.$emit('focus', {
                name: this.name,
                value: this.val,
            });
        },
        blur() {
            if(this.value.length > 0 || !this.required) {
                this.border = 'border-grey';
            }
            else {
                this.border = 'border-red-light';
            }

            this.$emit('blur', {
                name: this.name,
                value: this.val,
            });
        },
        isMobile() {
            return this.type.toLowerCase() == 'mobile';
        },
        isPassword() {
            return this.type.toLowerCase() == 'password';
        },
        toggle() {
            this.visible = !this.visible;
            this.current_type = this.visible ? "text" : "password";
            this.$refs.textbox.focus();
        },
        getIcon() {
            return require(this.visible ? "@/assets/icons/papsi_password_visible.svg" : "@/assets/icons/papsi_password_invisible.svg");
        },
    },
    watch: {
        val(val) {
            this.$emit('typing', {
                name: this.name,
                value: val,
            });
        },
        value(val) {
            this.val = val;
        },
        type(type) {
            this.current_type = type;
        },
        maxlength(n) {
            this.max = n;
        },
    },
}
</script>

<style scoped>
.papsi-icon-mt {
    margin-top: 7px;
}
.papsi-txt-lh {
    line-height: 40px;
}
</style>