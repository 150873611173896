<template>
    <div v-show="display">
        <div v-on:click="close" class="w-full h-screen fixed z-sidebar-screen papsi-modal-screen"></div>
        <div class="h-screen w-sidebar bg-white fixed z-sidebar">
            <div class="h-navbar bg-primary bg-contain bg-no-repeat papsi-sidebar-logo"></div>
            <div class="p-3">
                <div class="border-t">
                    <MenuControl v-for="item in menu" 
                        v-bind:href="item.href"
                        v-bind:icon="item.icon"
                        v-bind:key="item.id"
                        @clicked="close">
                        {{ item.text }}
                    </MenuControl>
                    <MenuControl @clicked="logout" icon="icons/papsi_logout.svg">Log Out</MenuControl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuControl from './../controls/MenuControl.vue';
import User from './../../services/User.js';

export default {
    name: 'v-sidebar',
    components: {
        MenuControl,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        menu: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            display: false,
        }
    },
    mounted() {
        this.display = this.show;
    },
    methods: {
        close() {
            this.display = false;
            this.$emit('closed');
        },
        logout() {
            this.close();
            this.$emit('showloader');

            let that = this,
                state = this.$store.state;

            User.logout(state.uuid, state.hash).then(function() {
                that.$cookies.remove('uuid');
                that.$cookies.remove('hash');

                if(that.$cookies.isKey('timestamp')) {
                    that.$cookies.remove('timestamp');
                }

                window.location.href = process.env.VUE_APP_BASE_URL + 'login';
            });
        }
    },
    watch: {
        show(n) {
            this.display = n;
        }
    }
}
</script>

<style>
.papsi-modal-screen {
    background-color: rgba(0, 0, 0, 0.85);
}
.papsi-sidebar-logo {
    background-image: url('https://media.pafcpic.ph/images/logo/papsi-navbar-sm.png');
}
</style>