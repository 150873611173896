<template>
    <div v-on:click="click" class="border-b py-2 flex">
        <div class="w-icon h-icon">
            <img v-bind:src="getIcon()" />
        </div>
        <div class="flex-grow text-md pl-2 flex justify-content items-center">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'v-menu',
    props: {
        href: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            required: true,
        },
    },
    methods: {
        click() {
            if(this.href != null) {
                this.$router.push(this.href);
            }
            
            this.$emit('clicked');
        },
        getIcon() {
            return require('./../../assets/' + this.icon);
        },
    }
}
</script>

<style>
.papsi-menu-lh {
    line-height: 24px;
}
</style>