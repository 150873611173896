<template>
    <div>
        <MessageBoxControl @closed="closed"
            v-bind:title="title"
            v-bind:show="show"
            v-bind:theme="theme"
            v-bind:message="message" />
        <NavbarSection title="Welcome to PAFCPIC" noback />
        <ContentWrapper class="mt-4">
            <div class="bg-white p-6 rounded shadow mb-4">
                <div class="text-sm text-grey-dark mb-4">
                    Your account has been created. 
                </div>
                <div>
                    <ButtonControl v-on:click="register">Continue</ButtonControl>
                </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapper from './../components/controls/ContentWrapperControl.vue';
import Election from './../services/Election.js';
import MessageBoxControl from './../components/controls/MessageBoxControl.vue';
import NavbarSection from './../components/sections/NavbarSection.vue';
import OTP from './../services/OTP.js';
import User from './../services/User.js';

export default {
    components: {
        ButtonControl,
        ContentWrapper,
        MessageBoxControl,
        NavbarSection,
    },
    data() {
        return {
            show: false,
            title: '',
            message: '',
            theme: 'error',
            otp: null,
        }
    },
    methods: {
        register() {
            let state = this.$store.state.credentials,
                that = this;

            this.$emit('showloader');
            User.authenticate(state.username, state.password, 1).then(function(response) {
                that.loadData(response);
            }).catch(function() {
                that.$emit('hideloader');
            });
        },
        closed() {
            this.show = false;
            window.location.href = process.env.VUE_APP_BASE_URL;
        },
        loadData(response) {
            let data = response.data,
                that = this;

            Election.register(data.data.uuid).then(function(response) {
                that.theme = 'success';
                that.title = 'Register';
                that.message = response.data.data.message;

                if(response.data.data.status == 0) {
                    that.start(data.data.uuid);
                }
                else {
                    that.show = true;
                }
            }).catch(function(error) {
                that.theme = 'error';
                that.title = 'Oops';
                that.message = error.response.data.message;
                that.show = true;
                that.$emit('hideloader');
            });
        },
        start(uuid) {
            let that = this;

            Election.start(uuid).then(function() {
                that.sendOtp(uuid);
            }).catch(function(error) {
                that.theme = 'error';
                that.title = 'Oops';
                that.message = error.response.data.message;
                that.show = true;
                that.$emit('hideloader');
            });
        },
        sendOtp(uuid) {
            let data = this.$store.state.registration,
                that = this;

            OTP.request(5, data.first_name, data.email, '+63' + data.mobile).then(function(response) {
                that.attachOtp(uuid, response.data.data.otp);
            }).catch(function(error) {
                that.theme = 'error';
                that.title = 'Oops';
                that.message = error.response.data.message;
                that.show = true;
                that.$emit('hideloader');
            });
        },
        attachOtp(uuid, otp) {
            let that = this;

            Election.attachOtp(uuid, otp).then(function() {
                that.show = true;
                that.$emit('hideloader');
            }).catch(function(error) {
                that.theme = 'error';
                that.title = 'Oops';
                that.message = error.response.data.message;
                that.show = true;
                that.$emit('hideloader');
            });
        }
    }
}
</script>