<template>
  <div class="text-base">
    <LoadingScreen v-show="loading" v-bind:randomizer="randomizer" />
    <FooterSection v-if="false" />
    <SidebarSection v-if="login" 
      v-bind:show="sidebar" 
      v-bind:menu="modules" 
      @showloader="showLoader" 
      @closed="closeSidebar" />
    <div v-if="loaded">
      <router-view @showloader="showLoader" 
        @electioncheck="electionCheck"
        @checkauth="checkAuth"
        @hideloader="hideLoader"
        @nofooter="nofooter" 
        @opensidebar="openSidebar" />
    </div>
    <div v-if="false" class="h-navbar"></div>
  </div>
</template>

<script>
import FooterSection from './components/sections/FooterSection.vue';
import LoadingScreen from './components/sections/LoadingScreenSection.vue';
import Module from './services/Module.js';
import SidebarSection from './components/sections/SidebarSection.vue';
import User from './services/User.js';
import mixin from './mixin.js';

export default {
  name: 'app',
  mixins: [
    mixin,
  ],
  components: {
    FooterSection,
    LoadingScreen,
    SidebarSection,
  },
  data: function() {
    return {
      login: false,
      loading: true,
      loaded: false,
      sidebar: false,
      modules: [],
      no_footer: false,
      randomizer: 0,
    }
  },
  mounted() {
    this.checkCookies();
  },
  methods: {
    electionCheck() {
      if(this.$store.state.election.voted || !this.$store.state.election.is_loaded) {
        this.$router.push('/dashboard');
      }
      else if(!this.$store.state.election.registered) {
        this.$router.push('/election');
      }
    },
    checkAuth(status = true) {
      if(status) {
        if(!this.$cookies.isKey('uuid') || !this.$cookies.isKey('hash')) {
          this.$router.push('/login');
        }
      }
      else
      {
        if(this.$cookies.isKey('uuid') && this.$cookies.isKey('hash')) {
          this.$router.push('/dashboard');
        }
      }
    },
    checkCookies() {
      if(this.$cookies.isKey('uuid') && this.$cookies.isKey('hash')) {
        let state = this.$store.state;

        state.login = true;
        state.uuid = this.$cookies.get('uuid');
        state.hash = this.$cookies.get('hash');

        if(!this.loaded) {
          this.showLoader();
          this.loadUserData(state.uuid);
        }
      }
      else
      {
        this.loading = false;
        this.loaded = true;
      }
    },
    loadUserData(uuid) {
      let that = this;

      User.getInfo(uuid).then(function(response) {
        let data = response.data.data,
            state = that.$store.state;

        state.email = data.email;
        state.mobile = data.mobile;
        state.first_name = data.first_name;
        state.last_name = data.last_name;
        state.account_no = data.account_no;
        state.serial_no = data.serial_no;
        state.key = data.key;
        state.lang = data.lang;
        state.username = data.username;
        state.password = data.password;
        state.status = data.status;
        state.registered = data.registered;
        state.mock_status = data.mock_status;
        state.election.registered = data.election.registered;
        state.election.voted = data.election.voted;
        state.election.migs = data.election.migs;

        that.login = true;
        that.loadModules();
      }).catch(function() {
        that.$cookies.remove('uuid');
        that.$cookies.remove('hash');
        window.location.href = process.env.VUE_APP_BASE_URL + 'login';
      });
    },
    loadModules() {
      let that = this;

      Module.get().then(function(response) {
        let data = response.data.data;
        
        that.modules = data;
        that.hideLoader();
        that.loaded = true;
      });
    },
    showLoader() {
      this.randomizer++;
      this.loading = true;
    },
    hideLoader() {
      this.loading = false;
    },
    openSidebar() {
      this.sidebar = true;
    },
    closeSidebar() {
      this.sidebar = false;
    },
    nofooter(v = true) { 
      this.no_footer = v;
    },
  }
}
</script>

<style>
body {
  background-color: #e0e0e0;
}
</style>