import { createRouter, createWebHistory } from 'vue-router'
// import ContactUsView from '../views/static/ContactUsView.vue'
// import DashboardView from '../views/DashboardView.vue'
// import DeactivateView from '../views/user/DeactivateAccountView.vue'
// import ElectionPolicyView from '../views/election/ElectionPolicyView.vue'
// import ElectionRegisterView from '../views/election/ElectionRegisterView.vue'
// import ElectionRatingsView from '../views/election/ElectionRatingsView.vue'
// import ElectionVoteAuditView from '../views/election/ElectionVoteAuditView.vue'
// import ElectionVoteBallotView from '../views/election/ElectionVoteBallotView.vue'
// import ElectionVoteBODView from '../views/election/ElectionVoteBODView.vue'
// import ElectionVoteElecomOfficerView from '../views/election/ElectionVoteElecomOfficerView.vue'
// import ElectionVoteElecomEPView from '../views/election/ElectionVoteElecomEPView.vue'
// import ElectionVoteSummaryView from '../views/election/ElectionVoteSummaryView.vue'
// import EmailSettingView from '../views/user/UpdateEmailSettingView.vue'
// import EndUserLicenseAgreementView from '../views/static/EndUserLicenseAgreementView.vue'
// import ForgotPasswordSuccessView from '../views/user/ForgotPasswordSuccessView.vue'
// import ForgotPasswordView from '../views/user/ForgotPasswordView.vue'
// import ForgotPasswordUpdateView from '../views/user/ForgotPasswordUpdateView.vue'
// import ForgotPasswordVerifyView from '../views/user/ForgotPasswordVerifyView.vue'
// import NeedHelpView from '../views/NeedHelpView.vue'
// import OurCompanyView from '../views/static/OurCompanyView.vue'
// import PasswordSettingView from '../views/user/UpdatePasswordSettingView.vue'
// import PrivacyPolicyView from '../views/static/PrivacyPolicyView.vue'
// import ProfileView from '../views/ProfileView.vue'
import LoginView from '../views/LoginView.vue'
// import MobileSettingView from '../views/user/UpdateMobileSettingView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import RegisterSuccessView from '../views/RegisterSuccessView.vue'
import RegisterExpressView from '../views/RegisterExpressView.vue'
import RegisterView from '../views/RegisterView.vue'
// import SettingsView from '../views/user/SettingsView.vue'
// import UsernameSettingView from '../views/user/UpdateUsernameSettingView.vue'
import WelcomeView from '../views/WelcomeView.vue'

const routes = [
  {
    path: '/',
    name: 'welcome-page',
    component: WelcomeView
  },
  {
    path: '/login',
    name: 'login-page',
    component: LoginView,
  },
  // {
  //   path: '/contact-us',
  //   name: 'contact-us-page',
  //   component: ContactUsView,
  // },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard-page',
  //   component: DashboardView,
  // },
  // {
  //   path: '/election',
  //   name: 'election-page',
  //   component: ElectionRegisterView,
  // },
  // {
  //   path: '/election/policy',
  //   name: 'election-policy-page',
  //   component: ElectionPolicyView,
  // },
  // {
  //   path: '/election/vote/bod',
  //   name: 'election-vote-bod-page',
  //   component: ElectionVoteBODView,
  // },
  // {
  //   path: '/election/ballot',
  //   name: 'election-vote-ballot-page',
  //   component: ElectionVoteBallotView,
  // },
  // {
  //   path: '/election/vote/elecom/officer',
  //   name: 'election-vote-elecom-of-page',
  //   component: ElectionVoteElecomOfficerView,
  // },
  // {
  //   path: '/election/vote/elecom/enlisted-personnel',
  //   name: 'election-vote-elecom-ep-page',
  //   component: ElectionVoteElecomEPView,
  // },
  // {
  //   path: '/election/vote/audit',
  //   name: 'election-vote-audit-page',
  //   component: ElectionVoteAuditView,
  // },
  // {
  //   path: '/election/ratings',
  //   name: 'election-ratings-page',
  //   component: ElectionRatingsView,
  // },
  // {
  //   path: '/election/vote/summary',
  //   name: 'election-vote-summary-page',
  //   component: ElectionVoteSummaryView,
  // },
  // {
  //   path: '/end-user-license-agreement',
  //   name: 'end-user-license-agreement-page',
  //   component: EndUserLicenseAgreementView,
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'forgot-password-page',
  //   component: ForgotPasswordView,
  // },
  // {
  //   path: '/forgot-password/success',
  //   name: 'forgot-password-success-page',
  //   component: ForgotPasswordSuccessView,
  // },
  // {
  //   path: '/forgot-password/verify',
  //   name: 'forgot-password-verify-page',
  //   component: ForgotPasswordVerifyView,
  // },
  // {
  //   path: '/forgot-password/update',
  //   name: 'forgot-password-update-page',
  //   component: ForgotPasswordUpdateView,
  // },
  // {
  //   path: '/help',
  //   name: 'need-help-page',
  //   component: NeedHelpView,
  // },
  // {
  //   path: '/our-company',
  //   name: 'our-company-page',
  //   component: OurCompanyView,
  // },
  // {
  //   path: '/privacy-policy',
  //   name: 'privacy-policy-page',
  //   component: PrivacyPolicyView,
  // },
  // {
  //   path: '/profile',
  //   name: 'profile-page',
  //   component: ProfileView,
  // },
  {
    path: '/signup',
    name: 'create-account-page',
    component: RegisterView,
  },
  {
    path: '/signup-express',
    name: 'create-account-express-page',
    component: RegisterExpressView,
  },
  {
    path: '/signup/done',
    name: 'create-account-done-page',
    component: RegisterSuccessView,
  },
  // {
  //   path: '/settings',
  //   name: 'settings-page',
  //   component: SettingsView,
  // },
  // {
  //   path: '/settings/password',
  //   name: 'password-setting-page',
  //   component: PasswordSettingView,
  // },
  // {
  //   path: '/settings/username',
  //   name: 'username-setting-page',
  //   component: UsernameSettingView,
  // },
  // {
  //   path: '/settings/mobile',
  //   name: 'mobile-setting-page',
  //   component: MobileSettingView,
  // },
  // {
  //   path: '/settings/email',
  //   name: 'email-setting-page',
  //   component: EmailSettingView,
  // },
  // {
  //   path: '/settings/deactivate',
  //   name: 'deactivate-account-page',
  //   component: DeactivateView,
  // },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
