<template>
    <div>
        <NavbarSection title="Page Not Found" />
        <ContentWrapper class="mt-3">
            <div class="bg-white p-6 rounded shadow">
                <img src="https://media.pafcpic.ph/images/page-not-found.jpg" />
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
import ContentWrapper from './../components/controls/ContentWrapperControl.vue';
import NavbarSection from './../components/sections/NavbarSection.vue';

export default {
    components: {
        ContentWrapper,
        NavbarSection,
    },
    mounted() {
        this.$emit('nofooter', false);
    }
}
</script>