import axios from 'axios';

axios.defaults.params = {};
axios.defaults.params.token = process.env.VUE_APP_API_TOKEN;

export default {

    getClientId() {
        return process.env.VUE_APP_CLIENT_ID;
    },

    endpoint(uri) {
        if(uri.substring(0, 1) == "/")
        {
            uri = uri.substring(1, uri.length);
        }

        return process.env.VUE_APP_API_URL + process.env.VUE_APP_API_VERSION + "/" + uri;
    },

    get(uri, params = {}) {
        return axios.get(this.endpoint(uri), {
            params: params,
            withCredentials: true,
        });
    },

    post(uri, params = {}) {
        return axios.post(this.endpoint(uri), params);
    },

    put(uri, params = {}) {
        return axios.put(this.endpoint(uri), params);
    },

    patch(uri, params = {}) {
        return axios.patch(this.endpoint(uri), {
            params: params,
            withCredentials: true,
        });
    },

    delete(uri, params = {}) {
        return axios.delete(this.endpoint(uri), {
            params: params,
            withCredentials: true,
        });
    }
}