import { createStore } from 'vuex'

export default createStore({
  state: {
    login: false,
    uuid: null,
    timestamp: null,
    hash: null,
    email: null,
    mobile: null,
    account_no: null,
    serial_no: null,
    first_name: null,
    last_name: null,
    key: null,
    lang: "en",
    username: null,
    password: null,
    status: null,
    registered: null,
    mock_status: false,
    credentials: {
      username: null,
      password: null,
    },
    registration: {
      last_name: '',
      first_name: '',
      middle_name: '',
      account_no: '',
      serial_no: '',
      username: '',
      email: '',
      mobile: '',
      password1: '',
      password2: '',
      agree: false,
    },
    election: {
      is_mock: false,
      is_loaded: false,
      ballot: null,
      registration_start: null,
      registration_started: false,
      voting_start: null,
      voting_started: false,
      voting_end: null,
      voting_ended: false,
      mock_start: null,
      mock_started: false,
      mock_end: null,
      mock_ended: false,
      registered: false,
      voted: false,
      migs: false,
      started: false,
      done: false,
    },
    candidates: {
      bod: [],
      elecom_of: [],
      elecom_ep: [],
      audit: [],
    },
    selected: {
      bod: [],
      elecom_of: [],
      elecom_ep: [],
      audit: [],
    },
    forgot: {
      uuid: null,
      name: null,
      username: null,
      email: null,
      mobile: null,
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
