<template>
    <button v-bind:disabled="disabled"
        v-bind:class="[ this.bg_color ]"
        v-on:click="click"  
        type="button"
        class="w-full py-4 rounded text-white text-md font-bold uppercase shadow outline-none">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'v-button',
    props: {
        theme: {
            type: String,
            default: 'success',
        },
        href: {
            type: String,
            default: null,
            required: false,
        },
        disabled: {
            type: Boolean,
        },
    },
    data() {
        return {
            selected_theme: null,
            bg_color: null,
            themes: {
                success: {
                    default: 'bg-success',
                    disabled: 'bg-success-disabled',
                },
                error: {
                    default: 'bg-error',
                    disabled: 'bg-error-disabled',
                },
                warning: {
                    default: 'bg-warning',
                    disabled: 'bg-warning-disabled',
                },
                info: {
                    default: 'bg-info',
                    disabled: 'bg-info-disabled',
                },
            },
        }
    },
    mounted() {
        this.selected_theme = this.themes[this.theme];
        this.bg_color = this.disabled ? this.selected_theme.disabled : this.selected_theme.default;
    },
    methods: {
        click() {
            if(this.href != null)
            {
                this.$router.push(this.href);
            }

            this.$emit('clicked');
        },
    },
    watch: {
        disabled() {
            this.bg_color = this.disabled ? this.selected_theme.disabled : this.selected_theme.default;
        },
        theme(theme) {
            this.selected_theme = this.themes[theme];
            this.bg_color = this.disabled ? this.selected_theme.disabled : this.selected_theme.default;
        },
    },
}
</script>