<template>
    <div>
        <ModalControl @closed="closed" v-bind:show="display" title="Enter OTP" theme="success">
            <div class="p-2">
                <div v-text="desc" class="mb-3 text-sm"></div>
                <AlertControl v-bind:show="has_error" 
                    @closes="alertClose"
                    :delay="5000"
                    theme="error"
                    autoclose
                    dismiss>
                    {{ message }}
                </AlertControl>
                <div class="mb-3">
                    <TextControl @typing="typing"
                        v-bind:value="value"
                        :maxlength="6"
                        name="otp" 
                        type="text" />
                </div>
                <div v-show="show_resend">
                    <div v-show="!freezed" class="mb-3">
                        <span v-on:click="resend" class="text-sm text-blue underline">Resend</span>
                    </div>
                    <div v-show="freezed" class="mb-3 text-sm text-grey-dark">
                        You can resend again after <span v-text="seconds"></span> seconds. 
                    </div>
                </div>
                <div class="flex">
                    <div class="flex-1 mr-2">
                        <ButtonControl v-on:click="validate" v-bind:disabled="disabled" theme="success">Validate</ButtonControl>
                    </div>
                    <div class="flex-1">
                        <ButtonControl v-on:click="cancel" theme="error">Cancel</ButtonControl>
                    </div>
                </div>
            </div>
        </ModalControl>
    </div>
</template>

<script>
import AlertControl from './../../components/controls/AlertControl.vue';
import ButtonControl from './../../components/controls/ButtonControl.vue';
import ModalControl from './ModalControl.vue';
import TextControl from './../../components/controls/TextControl.vue';
import OTP from './../../services/OTP.js';

export default {
    name: 'v-otp-modal',
    components: {
        AlertControl,
        ButtonControl,
        ModalControl,
        TextControl,
    },
    props: {
        type: {
            type: Number,
            default: 1,
        },
        show: {
            type: Boolean,
            default: false,
        },
        otp: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        mobile: {
            type: String,
            default: null,
        },
        desc: {
            type: String,
            default: 'Enter the one time pin we have sent to your email address or mobile number.',
        },
        pin: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            seconds: 120,
            freezed: false,
            has_error: false,
            display: false,
            disabled: true,
            show_resend: false,
            message: 'Something went wrong.',
            value: '',
        }
    },
    methods: {
        validate() {
            let that = this;

            this.$emit('showloader');
            OTP.validate(this.type, this.value).then(function() {
                that.display = false;
                that.$emit('matches');
                that.$emit('hideloader');
            }).catch(function(error) {
                that.value = '';
                that.message = error.response.data.message;
                that.has_error = true;
                that.$emit('hideloader');
            });
        },
        typing(obj) {
            this.value = obj.value;
            this.disabled = this.value.length != 6;
        },
        cancel() {
            this.display = false;
            this.$emit('cancel');
        },
        closed() {
            this.$emit('closed');
        },
        alertClose() {
            this.has_error = false;
        },
        resend() {
            this.$emit('showloader');
            let that = this;

            OTP.request(this.type, this.name, this.email, this.mobile, this.pin).then(function(response) {
                that.$emit('resend', response.data.data.otp);
                that.freezed = true;
                that.$emit('hideloader');
                that.startCountdown();
            });
        },
        startCountdown() {
            let that = this,
                t = setInterval(function() {
                that.seconds--;
                if(that.seconds == 0) {
                    that.seconds = 120;
                    that.freezed = false;
                    clearInterval(t);
                }
            }, 1000);
        },
        showResendLink() {
            let that = this,
                n = 0,
                t = setInterval(function() {
                    n++;

                    if(n == 60) {
                        that.show_resend = true;
                        clearInterval(t);
                    }
                }, 1000);
        }
    },
    watch: {
        show(n) {
            this.display = n;

            if(this.display) {
                this.showResendLink();
            }
            else {
                this.show_resend = false;
            }
        }
    }
}
</script>