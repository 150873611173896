import service from './../service.js';

export default {
    request(type, name, email = null, mobile = null, pin = null) {
        return service.post('/otp', {
            type: type,
            name: name,
            email: email,
            mobile: mobile,
            pin: pin,
        });
    },
    
    validate(type, otp) {
        return service.post('/otp/validate', {
            type: type,
            otp: otp,
        });
    }
}