<template>
    <div class="fixed w-full bg-white h-navbar shadow z-footer papsi-bottom-footer">
        <div class="flex text-xs font-bold">
            <div v-on:click="goToDashboard" v-bind:class="{ 'bg-grey-lighter' : ($route.name == 'dashboard-page') }" class="flex-1 text-center border-r h-navbar">
                <div><img class="w-icon h-icon mx-auto mt-2" src="@/assets/icons/papsi_home.svg" /></div>
                <div>Dashboard</div>
            </div>
            <div v-on:click="goToProfile" v-bind:class="{ 'bg-grey-lighter' : ($route.name == 'profile-page') }" class="flex-1 text-center border-r h-navbar">
                <div><img class="w-icon h-icon mx-auto mt-2" src="@/assets/icons/papsi_avatar.svg" /></div>
                <div>Profile</div>
            </div>
            <div v-on:click="goToSettings" v-bind:class="{ 'bg-grey-lighter' : ($route.name == 'settings-page') }" class="flex-1 text-center border-r h-navbar">
                <div><img class="w-icon h-icon mx-auto mt-2" src="@/assets/icons/papsi_settings.svg" /></div>
                <div>Settings</div>
            </div>
            <div v-on:click="goToHelp" v-bind:class="{ 'bg-grey-lighter' : ($route.name == 'need-help-page') }" class="flex-1 text-center h-navbar">
                <div><img class="w-icon h-icon mx-auto mt-2" src="@/assets/icons/papsi_help.svg" /></div>
                <div>Help</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToDashboard() {
            this.$router.push('/dashboard');
        },
        goToProfile() {
            this.$router.push('/profile');
        },
        goToSettings() {
            this.$router.push('/settings');
        },
        goToHelp() {
            this.$router.push('/help');
        }
    }
}
</script>

<style>
.papsi-bottom-footer {
    bottom: 0;
    left: 0;
}
</style>