<template>
    <div class="max-w-limit m-auto px-4 mb-2">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'v-content-wrapper'
}
</script>