<template>
    <div>
        <div class="h-navbar shadow bg-white flex px-2">
            <div v-show="!noback" class="h-menu w-menu papsi-back-icon">
                <img v-on:click="back" src="@/assets/icons/papsi_back_dark.svg" />
            </div>
            <div class="flex-grow pl-1 text-lg flex justify-content items-center h-navbar">
                <span v-text="title"></span>
            </div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        noback: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        back() {
            this.$router.back();
        }
    }
}
</script>

<style>
.papsi-navbar-title {
    line-height: 60px;
}
.papsi-back-icon {
    margin-top: 13px;
}
</style>