<template>
    <div>
        <div class="w-full h-screen bg-primary fixed z-loading flex">
            <div v-bind:class="[ animation ]" class="w-logo h-logo mx-auto bg-contain bg-no-repeat papsi-logo"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        randomizer: {
            type: Number,
        }
    },
    data() {
        return {
            animation: 'papsi-pulse',
            animations: [
                'papsi-pulse',
                'papsi-flip',
                'papsi-swing',
                'papsi-wobble',
                'papsi-hithere',
            ],
        }
    },
    mounted() {
        this.animation = this.animations[this.getRandom(0, this.animations.length)];
    },
    methods: {
        getRandom(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            
            return Math.floor(Math.random() * (max - min) + min);
        }
    },
    watch: {
        randomizer() {
            this.animation = this.animations[this.getRandom(0, this.animations.length)];
        }
    }
}
</script>

<style>
.papsi-logo {
    margin-top: calc((100vh - 180px) / 2);
    background-image: url('https://media.pafcpic.ph/images/logo/pafcpic.png');
}
.papsi-version {
    bottom: 0px;
}
.papsi-pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}
.papsi-flip {
	backface-visibility: visible !important;
	animation: flip 2s ease infinite;
}
@keyframes flip {
	0% {
		transform: perspective(400px) rotateY(0);
		animation-timing-function: ease-out;
	}
	40% {
		transform: perspective(400px) translateZ(150px) rotateY(170deg);
		animation-timing-function: ease-out;
	}
	50% {
		transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		animation-timing-function: ease-in;
	}
	80% {
		transform: perspective(400px) rotateY(360deg) scale(.95);
		animation-timing-function: ease-in;
	}
	100% {
		transform: perspective(400px) scale(1);
		animation-timing-function: ease-in;
	}
}
.papsi-swing {
	transform-origin: top center;
	animation: swing 2s ease infinite;
}
@keyframes swing {
	20% { transform: rotate(15deg); }	
	40% { transform: rotate(-10deg); }
	60% { transform: rotate(5deg); }	
	80% { transform: rotate(-5deg); }	
	100% { transform: rotate(0deg); }
}
.papsi-wobble {
  animation: wobble 2s ease infinite;
}
@keyframes wobble {
  0% { transform: translateX(0%); }
  15% { transform: translateX(-25%) rotate(-5deg); }
  30% { transform: translateX(20%) rotate(3deg); }
  45% { transform: translateX(-15%) rotate(-3deg); }
  60% { transform: translateX(10%) rotate(2deg); }
  75% { transform: translateX(-5%) rotate(-1deg); }
  100% { transform: translateX(0%); }
}
.papsi-hithere {
  animation: hithere 1s ease infinite;
}
@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}
.papsi-hinge {
  animation: hinge 2s ease infinite;
}
@keyframes hinge {
  0% { transform: rotate(0); transform-origin: top left; animation-timing-function: ease-in-out; }  
  20%, 60% { transform: rotate(80deg); transform-origin: top left; animation-timing-function: ease-in-out; }  
  40% { transform: rotate(60deg); transform-origin: top left; animation-timing-function: ease-in-out; } 
  80% { transform: rotate(60deg) translateY(0); opacity: 1; transform-origin: top left; animation-timing-function: ease-in-out; } 
  100% { transform: translateY(700px); opacity: 0; }
}
</style>