<template>
    <div class="w-full h-screen flex flex-col">
        <div class="bg-primary py-6 w-full text-center">
            <div>
                <img src="https://media.pafcpic.ph/images/logo/pafcpic-nav.png" />
            </div>
            <div class="text-white font-bold text-xl mt-6">WELCOME KA-MAY-ARI</div>
            <div class="text-white font-thin text-md italic mt-1">Serving the AFP since 1981</div>
        </div>
        <div class="w-full">
            <ContentWrapper class="mt-6">
                <div class="mb-2">
                    <ButtonControl href="/signup">Creane an account</ButtonControl>
                </div>
                <div class="mb-2">
                    <ButtonControl href="/signup-express" theme="info">Express Lane</ButtonControl>
                </div>
            </ContentWrapper>
        </div>
    </div>
</template>

<script>
import ButtonControl from './../components/controls/ButtonControl.vue';
import ContentWrapper from './../components/controls/ContentWrapperControl.vue';
// import MessageBoxControl from './../components/controls/MessageBoxControl.vue';
// import OtpModalControl from './../components/controls/OTPModalControl.vue';
// import TextControl from './../components/controls/TextControl.vue';
import User from './../services/User.js';

export default {
    components: {
        ButtonControl,
        ContentWrapper,
        // MessageBoxControl,
        // OtpModalControl,
        // TextControl,
    },
    data() {
        return {
            attempt: 0,
            display: false,
            message: '',
            disabled: true,
            username: '',
            password: '',
            freezed: false,
            reset: 60,
            seconds: 60,
            delay: 4000,
            show_otp: false,
            otp: null,
            pin: null,
            name: null,
            mobile: null,
            email: null,
            uuid: null,
            type: 8,
        }
    },
    mounted() {
        if(this.$store.state.login) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        cancel() {
            this.show_otp = false;
        },
        resend(otp) {
            this.otp = otp;
        },
        showLoader() {
            this.$emit('showloader');
        },
        hideLoader() {
            this.$emit('hideloader');
        },
        matches() {
            this.$emit('showloader');
            let that = this;

            User.revert(this.uuid).then(function() {
                that.login();
            });
        },
        login() {
            this.$emit('showloader');
            let that = this;

            User.authenticate(this.username, this.password, this.attempt + 1).then(function(response) {
                let data = response.data.data;

                if(data.ask_otp) {
                    that.name = data.name;
                    that.mobile = data.mobile;
                    that.email = data.email;
                    that.uuid = data.uuid;
                    that.$emit('hideloader');
                    that.show_otp = true;
                }
                else {
                    that.loadData(response);
                }

            }).catch(function(error) {
                that.password = '';
                that.attempt++;
                that.message = error.response.data.message;
                that.display = true;
                
                if(that.attempt == 3) {
                    that.freezed = true;
                    that.freezeLogin();
                }

                that.$emit('hideloader');
            });
        },
        freezeLogin() {
            let that = this,
                t = setInterval(function() {
                    that.seconds--;

                    if(that.seconds == 0) {
                        that.seconds = this.reset;
                        that.attempt = 0;
                        that.freezed = false;
                        clearInterval(t);
                    }

            }, 1000);
        },
        typing(obj) {
            this[obj.name] = obj.value;
            this.enableButton();
        },
        enableButton() {
            this.disabled = (this.username.length == 0 || this.password.length == 0) || this.freezed;
        },
        loadData(response) {
            let data = response.data;

            if(!this.$cookies.isKey('uuid') && !this.$cookies.isKey('hash')) {
                this.$cookies.set('uuid', data.data.uuid);
                this.$cookies.set('hash', data.data.hash);
                this.$cookies.set('timestamp', data.data.timestamp);
            }

            window.location.href = process.env.VUE_APP_BASE_URL + 'dashboard';
        },
        closes() {
            this.display = false;
        },
    },
    watch: {
        attempt(n) {
            this.freezed = n >= 3;
        },
    },
}
</script>